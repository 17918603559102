import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { graphql, PageProps } from "gatsby"
import SEO from "../../components/seo"
import BrandBox from "../../components/BrandBox"
// @ts-ignore
import MussiGallery from "../../components/SwiperSlider/MussiGallery"
import { FluidObject } from "gatsby-image"

interface IMussiBrandProps extends PageProps<{
  mussiLogo: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}> {
  className?: string
}

const content = `
<p>Although designed for a specific functionality, and malleable in its form, a MUSSI sofa or bed is always a straightforward, emotionally expressive creation.
<br>
<br>
 Each new product is sophisticated study which experiments with technology and fine craftsmanship, tradition and innovation, new materials and luxurious finishes but retains its essential simplicity. These are not cold elements of design that act as a simple interface between ourselves and the word, but living coexisting energies that enable us to recognize ourselves in our choices, to transform a daily action – like welcoming friends or relaxing after a hard working day – into something more personal, and to pay continuous attention to the ethics of beauty, which soon become the ability to live in harmony.
 <br>
 <br>
 Our pieces are objects that transcend passing fads, objects that create new trends, yet are the result of the elegance which create lifestyles and reflect lives spent working on style.</p>
`

const Mussi: React.FC<IMussiBrandProps> = ({ className, path, data }) => {
  return (
    <Layout lang="en" page={path}>
      <SEO
        title="Mussi is an Italian manufacturing company specializing in handcrafted classic, luxury and custom-made
sofas, beds and armchairs."
        description="Mussi is an Italian fine furniture manufacturer that offers functional and innovative furnishing."
      />
      <div className={className}>
        <section className="section brand-page">
          <BrandBox
            gallery={<MussiGallery />}
            title="Italian sofas, beds and armchairs"
            content={content}
            logo={data.mussiLogo.childImageSharp.fluid}
            link="http://mussi.it/"
          />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    mussiLogo: file(relativePath: {eq: "ci_mussilogo4x.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default styled(Mussi)`
  top: 0;
  padding-top: 25px;
  position:relative;
  max-width: 1200px;
  margin: 50px auto auto auto;
  @media screen and (min-width: 1051px) {
    margin-top: 80px;
  }
  padding-bottom: 100px;
`
